"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverviewGraph = exports.anchorPoints = void 0;
exports.buildImageMetadata = buildImageMetadata;
exports.buildEdge = buildEdge;
exports.getTextWidth = getTextWidth;
const css = __importStar(require("css/exports.module.scss"));
const AppNode_1 = require("./AppNode");
const g6_react_node_1 = require("@antv/g6-react-node");
const graph_tools_1 = require("@pulse/graph-tools");
const lodash_1 = require("lodash");
const ObjectNode_1 = require("@pulse/application-overview/ObjectNode");
const _hooks_1 = require("@hooks");
const graphin_1 = __importStar(require("@antv/graphin"));
const applications_1 = require("@ui/applications");
const react_1 = __importStar(require("react"));
exports.anchorPoints = [[0, 0],
    [0, 0.5],
    [0.1, 0],
    [0.2, 0],
    [0.3, 0],
    [0.4, 0],
    [0.5, 0],
    [0.6, 0],
    [0.7, 0],
    [0.8, 0],
    [0.9, 0],
    [1, 0],
    [1, 0.5],
    [0, 1],
    [0.1, 1],
    [0.2, 1],
    [0.3, 1],
    [0.4, 1],
    [0.5, 1],
    [0.6, 1],
    [0.7, 1],
    [0.8, 1],
    [0.9, 1],
    [1, 1]];
graphin_1.G6.registerNode('app-center-display-image', {
    draw: (cfg, group) => {
        var _a, _b;
        const nodeHeight = Array.isArray(cfg.size) ? cfg.size[1] : (_a = cfg.size) !== null && _a !== void 0 ? _a : 30;
        const nodeWidth = Array.isArray(cfg.size) ? cfg.size[0] : (_b = cfg.size) !== null && _b !== void 0 ? _b : 30;
        const outerrect = group === null || group === void 0 ? void 0 : group.addShape('rect', {
            attrs: {
                fill: '',
                height: nodeHeight + 20,
                stroke: '',
                width: nodeWidth + 20,
                x: -(nodeWidth + 20) / 2,
                y: -(nodeHeight + 20) / 2
            },
            draggable: true,
            name: 'node'
        });
        group === null || group === void 0 ? void 0 : group.addShape('rect', {
            attrs: {
                cursor: 'pointer',
                fill: '#FAFAFA',
                height: nodeHeight + 10,
                radius: 2,
                stroke: '#D9D9D9',
                width: nodeWidth + 10,
                x: -(nodeWidth + 10) / 2,
                y: -(nodeHeight + 10) / 2
            },
            draggable: true,
            name: 'border'
        });
        group === null || group === void 0 ? void 0 : group.addShape('image', {
            attrs: {
                cursor: 'pointer',
                height: nodeHeight,
                img: cfg.img,
                width: nodeWidth,
                x: -nodeWidth / 2,
                y: -nodeHeight / 2
            },
            draggable: true,
            name: 'image'
        });
        return outerrect;
    }
});
graphin_1.G6.registerNode('app-object', Object.assign(Object.assign({}, (0, g6_react_node_1.createNodeFromReact)(ObjectNode_1.ObjectNode)), { getAnchorPoints() {
        return undefined;
    } }));
graphin_1.G6.registerNode('app-display', Object.assign(Object.assign({}, (0, g6_react_node_1.createNodeFromReact)(AppNode_1.AppNode)), { getAnchorPoints() {
        return undefined;
    } }));
const registerEdge = ({ permissions, volume }) => {
    graphin_1.G6.registerEdge(`${permissions}-${volume}`, {
        afterDraw(_, group) {
            if (!group) {
                return;
            }
            const shape = group.getFirst();
            const startPoint = shape.getPoint(0);
            const circleCount = volume === 'low' ? 1 : 2;
            const combinations = [];
            for (let i = 0; i < circleCount; i++) {
                combinations.push({
                    circleCountIndex: i,
                    permission: 'read'
                });
            }
            if (permissions === 'readwrite') {
                for (let i = 0; i < circleCount; i++) {
                    combinations.push({
                        circleCountIndex: i,
                        permission: 'readwrite'
                    });
                }
            }
            combinations.forEach((combination) => {
                const { permission, circleCountIndex } = combination;
                const circle = group.addShape('circle', {
                    attrs: {
                        fill: permission === 'read' ? css.read : css.write,
                        r: 5,
                        x: startPoint === null || startPoint === void 0 ? void 0 : startPoint.x,
                        y: startPoint === null || startPoint === void 0 ? void 0 : startPoint.y
                    },
                    name: 'circle-shape'
                });
                const randomOffset = (Math.random() * 0.1) + 0.2;
                circle.animate((ratio) => {
                    let newRatio = ratio;
                    if (permission === 'readwrite') {
                        newRatio += circleCountIndex * randomOffset;
                        newRatio = newRatio > 1 ? newRatio - 1 : newRatio;
                    }
                    else if (permission === 'read') {
                        newRatio = 1 - newRatio;
                        newRatio -= circleCountIndex * randomOffset;
                        newRatio = newRatio < 0 ? newRatio + 1 : newRatio;
                    }
                    const tmpPoint = shape.getPoint(newRatio);
                    return {
                        x: tmpPoint.x,
                        y: tmpPoint.y
                    };
                }, {
                    duration: 3000,
                    repeat: true
                });
            });
        },
        update: undefined
    }, 'quadratic');
};
registerEdge({
    permissions: 'read',
    volume: 'low'
});
registerEdge({
    permissions: 'read',
    volume: 'high'
});
registerEdge({
    permissions: 'readwrite',
    volume: 'low'
});
registerEdge({
    permissions: 'readwrite',
    volume: 'high'
});
function buildImageMetadata(id, name, label) {
    const logoPath = applications_1.PulseAppLogos[name];
    if (!logoPath) {
        return Promise.resolve({
            id: id,
            label: label,
            name: name
        });
    }
    return new Promise((resolve) => {
        const logo = new Image();
        logo.onload = () => {
            resolve({
                height: logo.height,
                id: id,
                label: label,
                name: name,
                path: logoPath,
                width: logo.width
            });
        };
        logo.src = logoPath;
    });
}
function buildEdge(read, write, lineType, source, target) {
    const threshhold = 7;
    let type = lineType;
    if (type === '') {
        if (write > 0) {
            if (write >= threshhold || read >= threshhold) {
                type = graph_tools_1.GraphLines[11].value;
            }
            else {
                type = graph_tools_1.GraphLines[10].value;
            }
        }
        else if (read >= threshhold) {
            type = graph_tools_1.GraphLines[9].value;
        }
        else {
            type = graph_tools_1.GraphLines[8].value;
        }
    }
    return {
        source: source,
        style: {
            endArrow: {
                fill: css.overviewStrokeColor,
                path: write ? graphin_1.G6.Arrow.triangle() : graphin_1.G6.Arrow.rect()
            },
            startArrow: {
                fill: css.overviewStrokeColor,
                path: read ? graphin_1.G6.Arrow.triangle() : graphin_1.G6.Arrow.rect()
            },
            stroke: css.overviewStrokeColor
        },
        target: target,
        type: type
    };
}
function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }
    if (font) {
        context.font = font;
    }
    const metrics = context.measureText(text);
    return metrics.width;
}
const OverviewGraph = ({ children, data, maxZoom, minZoom, layoutOptions }) => {
    const graphRef = react_1.default.createRef();
    const [graphData, setgraphData] = (0, react_1.useState)({
        combos: [],
        edges: [],
        nodes: []
    });
    const numNode = graphData.nodes.length;
    const distance = (0, react_1.useCallback)((linkDistance) => (linkDistance ?
        Math.max(linkDistance, Math.min(300, 225 + (numNode / 2))) :
        undefined), [numNode]);
    const [layoutOpts, setLayoutOpts] = (0, react_1.useState)(Object.assign(Object.assign({}, layoutOptions), { linkDistance: distance(layoutOptions.linkDistance) }));
    const previousLayout = (0, _hooks_1.usePrevious)(layoutOptions, layoutOptions);
    (0, react_1.useEffect)(() => {
        if (data !== graphData) {
            setgraphData(data);
        }
    }, [data, graphData]);
    (0, react_1.useEffect)(() => {
        if (!(0, lodash_1.isEqual)(layoutOptions, previousLayout)) {
            setLayoutOpts(Object.assign(Object.assign({}, layoutOptions), { linkDistance: distance(layoutOptions.linkDistance) }));
        }
    }, [layoutOptions, distance, previousLayout]);
    (0, react_1.useEffect)(() => {
        var _a;
        const graph = (_a = graphRef.current) === null || _a === void 0 ? void 0 : _a.graph;
        if (!graph || graphData.nodes.length <= 0) {
            return;
        }
        graph.setMaxZoom(maxZoom);
        graph.setMinZoom(minZoom);
        graph.render();
    }, [graphRef, graphData, layoutOpts, maxZoom, minZoom]);
    const { ActivateRelations, DragCanvas, DragCombo, DragNode, ZoomCanvas } = graphin_1.Behaviors;
    return (react_1.default.createElement(graphin_1.default, { containerId: 'app-graph', data: graphData, fitView: true, fitViewPadding: 24, layout: layoutOpts, ref: graphRef },
        react_1.default.createElement(ActivateRelations, { activeState: 'activeState' }),
        react_1.default.createElement(DragCanvas, null),
        react_1.default.createElement(DragNode, { onlyChangeComboSize: true }),
        react_1.default.createElement(DragCombo, null),
        react_1.default.createElement(ZoomCanvas, { disabled: true }),
        children));
};
exports.OverviewGraph = OverviewGraph;
